export class JSXFactory {

    private static Fragment = "<></>";

    public static createElement(tagName: string, attributes: any | null, ...children: any[]): Element | DocumentFragment | Comment {
        if (tagName === this.Fragment) {
            return document.createDocumentFragment();
        }

        const element = document.createElement(tagName);
        if (attributes) {
            this.setAttributes(element, attributes);
        }

        for (const child of children) {
            this.appendChild(element, child);
        }

        return element;
    }

    private static setAttributes(element: HTMLElement, attributes: any) {
        for (const key of Object.keys(attributes)) {
            const attributeValue = attributes[key];

            if (key === "className") { // JSX does not allow class as a valid name
                element.setAttribute("class", attributeValue);
            } else if (key.startsWith("on") && typeof attributes[key] === "function") {
                element.addEventListener(key.substring(2), attributeValue);
            } else {
                // <input disable />      { disable: true }
                // <input type="text" />  { type: "text"}
                if (typeof attributeValue === "boolean" && attributeValue) {
                    element.setAttribute(key, "");
                } else {
                    element.setAttribute(key, attributeValue);
                }
            }
        }
    }

    private static buildKoComment(attrs: NamedNodeMap): string {
        let content: string[] = [];
        for(let i=0; i<attrs.length;i++) {
            let attr: Attr = attrs.item(i);
            content.push(attr.nodeName + ': ' + attr.nodeValue);
        }
        return content.join(', ');
    }

    private static appendChild(parent: Node, child: any) {
        if (typeof child === "undefined" || child === null) {
            return;
        }

        if (Array.isArray(child)) {
            for (const value of child) {
                this.appendChild(parent, value);
            }
        }
        else if (typeof child === "string") {
            parent.appendChild(document.createTextNode(child));
        }
        else if (child instanceof Node) {
            let nodeNameLower = child.nodeName.toLocaleLowerCase();
            if(nodeNameLower == 'ko') {
                parent.appendChild(document.createComment(
                    ' ko ' + this.buildKoComment((child as Element).attributes) + ' '
                ));
                while(child.childNodes.length > 0) {
                    parent.appendChild(child.childNodes[0]);
                }
                parent.appendChild(document.createComment(
                    ' /ko '
                ));
            }
            else if(nodeNameLower == 'viewframe' || nodeNameLower == 'view-frame' || nodeNameLower == 'routeframe' || nodeNameLower == 'route-frame') {
                let isView = nodeNameLower == 'viewframe' || nodeNameLower == 'view-frame';

                let viewFrameDiv = document.createElement("div");
                let attrs = (child as Element).attributes;
                if(isView) {
                    let nameAttribute = attrs.getNamedItem('name');
                    if(!nameAttribute) {
                        throw 'The <view-frame></view-frame> element must have "name" attribute!';
                    }
                    viewFrameDiv.setAttribute('data-view-frame', nameAttribute.value);
                }
                else {
                    viewFrameDiv.setAttribute('data-route-frame', '1');
                }
                for(let i = 0; i < attrs.length; i++) {
                    let attr: Attr = attrs.item(i);
                    if(attr.nodeName.toLocaleLowerCase() !== 'name') {
                        viewFrameDiv.setAttribute(attr.nodeName, attr.nodeValue);
                    }
                }                
                parent.appendChild(viewFrameDiv);
            }
            else {
                parent.appendChild(child);
            }
        }
        else if (typeof child === "boolean") {
            // <>{condition && <a>Display when condition is true</a>}</>
            // if condition is false, the child is a boolean, but we don't want to display anything
        }
        else {
            parent.appendChild(document.createTextNode(String(child)));
        }
    }
}

export function h(tagName: string, attributes: any | null, ...children: any[]) {
	return JSXFactory.createElement(tagName, attributes, ...children);
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
           [elemName: string]: any;
        }
    }
}
