//import * as jQuery from 'jquery';
import moment from 'moment';

import { i18n } from "i18next-ko";


//import '@progress/kendo-ui/css/web/kendo.common.core.css';
//import '@progress/kendo-ui/css/web/kendo.bootstrap.css';

import { h } from "./../tracejs/src/utils/JSXFactory";

//import * as ko from 'knockout';

//(window as any).ko = ko;
//(window as any).jQuery = jQuery;
//(window as any).$ = jQuery;
(window as any).moment = moment;
(window as any).h = h;
(window as any).i18n = i18n;

//import '@progress/kendo-ui/js/kendo.core';
//import '@progress/kendo-ui/js/kendo.ui.core';
//import '@progress/kendo-ui/js/kendo.datepicker';
//import '@progress/kendo-ui/js/kendo.grid';

//import 'kendo';
//import '@progress/kendo-ui';

//import 'knockout-mapping';
//import 'knockout-kendo/build/knockout-kendo';

import 'bootstrap'; 
